<template>
  <div class="content">
    <!-- <div class="alert alert-success" style=" margin-bottom: 20px !important; margin-top: 0px !important; ">
            <strong>
                <i class="ti-info-alt">
                    !
                </i>
            </strong>
                为了触达用户更好的体验以及防止被微信封杀营销通知内容，建议在会员卡
                <el-button style="margin-top: -15px;" size="mini" type="warning" @click="returnClike">返回</el-button>
        </div> -->
    <div class="c_content" style="width: auto; float: none">
      <div>
        <div class="gengduoxian">
          <i class="infoLine"></i>活动内容
          <span class="note-inf"> 带<a style="color: red">*</a>为必填项 </span>
        </div>
      </div>
      <el-form
        ref="editForm"
        :model="editForm"
        :rules="editFormRules"
        label-width="80px"
        class="frame"
      >
        <div class="form-group">
          <el-form-item label="活动名称：" label-width="120px" prop="first">
            <el-input
              v-model="editForm.first"
              maxlength="20"
              show-word-limit
              style="width: 650px"
            ></el-input>
          </el-form-item>
          <el-form-item label="参与对象：" label-width="120px">
            <span style="display: inline-block; padding-top: 0px">
              <span style="color: red">
                {{ this.editForm.sendObj }}
              </span>
            </span>
          </el-form-item>

          <template v-for="(item, index) in formOptions">
            <el-form-item
              v-if="item.stat === 'string' && !item.name.includes('备注')"
              :label="item.name + '：'"
              :key="index"
              label-width="120px"
            >
              <el-input
                v-model="editForm.content[item.field]"
                style="width: 650px"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-else-if="item.stat === 'datetime'"
              :label="item.name + '：'"
              :key="index"
              label-width="120px"
            >
              <el-date-picker
                v-model="editForm.content.field"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
          </template>

          <el-form-item label="模板示例：" label-width="120px">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 10 }"
              :disabled="true"
              v-model="editForm.example"
            ></el-input>
          </el-form-item>
          <el-form-item label="发送方式：" label-width="120px" prop="sendMethod">
            <el-radio v-model="editForm.sendMethod" label="1">自定义链接</el-radio>
            <el-radio v-model="editForm.sendMethod" label="2">发送礼品</el-radio>
            <el-radio v-model="editForm.sendMethod" label="3">跳转小程序</el-radio>
          </el-form-item>
          <el-form-item
            v-if="editForm.sendMethod === '1'"
            label="自定义链接："
            label-width="120px"
            prop="sendurl"
          >
            <el-input v-model="editForm.sendurl" style="width: 650px"></el-input>
          </el-form-item>
          <el-form-item
          v-if="editForm.sendMethod === '3'"
          label="跳转小程序："
          label-width="120px"
          prop="miniprogram"
        >
        <div style="width:100%;display:flex;flex;flex-direction:column;">
            <div style="width:100%;display:flex;flex;flex-direction:row;">
              <span>APPID：</span>
              <el-input
                type="text"
                style="width: 600px; height: 60px; line-height: 60px;margin-left:20px"
                v-model="editForm.miniprogram.appid"
              ></el-input>
            </div>
            <div style="width:100%;display:flex;flex;flex-direction:row;">
              <span>跳转路径：</span>
              <el-input
                type="text"
                style="width: 600px; height: 60px; line-height: 60px;margin-left:10px"
                v-model="editForm.miniprogram.pathindex"
              ></el-input>
            </div>
        </div>
        </el-form-item>
          <div class="tips" style="margin-left: 118px">
            {{ tipsInfo }}
            <a
              style="color: #2196f3"
              href="https://mp.weixin.qq.com/mp/opshowpage?action=newoplaw#t3-4"
              target="_blank"
              >微信公众号发送内容规范</a
            >
          </div>
        </div>
        <div v-if="editForm.sendMethod === '2'">
          <div class="headings gengduoxian no-wechat-pay-gift">
            <i class="infoLine"></i>活动奖励
          </div>
        </div>
        <div
          v-if="editForm.sendMethod === '2'"
          class="form-group gift-inputs no-wechat-pay-gift"
        >
          <el-form-item label="活动奖励：" label-width="120px" prop="prize">
            <el-radio-group v-model="prize">
              <el-radio :label="0">自由组合</el-radio>
              <el-radio :label="1">营销礼包</el-radio>
            </el-radio-group>
            <template v-if="prize === 0">
              <div>
                <el-checkbox v-model="coupon" :label="0">赠送优惠券</el-checkbox>
                <template v-if="coupon">
                  <div
                    class="col-md-6 coupon-add charge-rule-box"
                    style="
                      float: none;
                      margin-top: 10px;
                      margin-left: -22px;
                      width: 600px !important;
                    "
                  >
                    <div
                      v-for="(item, index) in arrayGiftData"
                      :key="index"
                      style="margin-top: 15px; display: flex; align-items: center"
                    >
                      <span style="margin-left: 15px">送</span> &nbsp;
                      <el-select v-model="item.type" @change="changeType(index, item)">
                        <el-option
                          v-for="item in arrayGiftData.giftOptions"
                          :key="item.cou_id"
                          :label="item.cou_title + '(剩余' + item.surplus + '数量)'"
                          :value="item.cou_id"
                          :disabled="item.disabled"
                          @click.native="handleChange(item)"
                        >
                        </el-option>
                      </el-select>
                      <el-input-number
                        v-model="item.recSurplus"
                        :min="1"
                      ></el-input-number>
                      张
                      <i
                        class="el-icon-remove-outline"
                        style="color: red; font-size: 20px; margin: 0 5px"
                        @click="deletes(item, index)"
                      ></i>
                    </div>
                    <span
                      style="margin-left: 15px; color: #3d7fff; cursor: pointer"
                      @click="add"
                      @change="$forceUpdate()"
                      >+ 添加</span
                    >
                  </div>
                </template>
              </div>
              <div>
                <el-checkbox v-model="timesCard" :label="1">赠送计次卡</el-checkbox>
                <template v-if="timesCard">
                  <div
                    class="col-md-6 coupon-add charge-rule-box"
                    style="
                      float: none;
                      margin-top: 10px;
                      margin-left: -22px;
                      width: 600px !important;
                    "
                  >
                    <div
                      v-for="(item, index) in arrayTcountData"
                      :key="index"
                      style="margin-top: 15px; display: flex; align-items: center"
                    >
                      <span style="margin-left: 15px">送</span> &nbsp;
                      <el-select
                        v-model="item.type"
                        @change="changeTypeTcount(index, item)"
                      >
                        <el-option
                          v-for="item in arrayTcountData.tcountOptions"
                          :key="item.cou_id"
                          :label="item.cou_title + '(剩余' + item.surplus + '数量)'"
                          :value="item.cou_id"
                          :disabled="item.disabled"
                          @click.native="tcountChange(item)"
                        >
                        </el-option>
                      </el-select>
                      <el-input-number
                        v-model="item.recSurplus"
                        :min="0"
                      ></el-input-number>
                      张
                      <i
                        class="el-icon-remove-outline"
                        style="color: red; font-size: 20px; margin: 0 5px"
                        @click="deletesTcount(item, index)"
                      ></i>
                    </div>
                    <span
                      style="margin-left: 15px; color: #3d7fff; cursor: pointer"
                      @click="addTcount"
                      @change="$forceUpdate()"
                      >+ 添加</span
                    >
                  </div>
                </template>
              </div>
            </template>
          </el-form-item>
        </div>
      </el-form>
      <div class="text-center">
        <el-button @click="returnClike">返回</el-button>
        <el-button type="primary" @click="submitForm('editForm')">发送</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/private";
import temp from "../../api/temp";
import moment from "moment";

export default {
  name: "infoLabelModel",
  data() {
    let isTrueUrl = (rule, value, callback) => {
      let reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      if (!reg.test(value)) {
        callback(new Error("请输入正确的链接"));
      } else {
        callback();
      }
    };

    return {
      laberCreat: this.$route.query, //标签数据格式
      editForm: {
        tagId: "",
        first: "",
        sendObj: "",
        sendType: "",
        sendurl: "",
        miniprogram: {
          appid: "",
          pathindex: "",
        },
        title: "",
        content: {},
        tempId: "",
        example: "",
      }, //发送表单内容
      formOptions: [],

      editFormRules: {
        first: [{ required: true, message: "请输入活动名称", trigger: "blur" }],
        // remark:[{ required: true, message: "请输入发送内容", trigger: "blur"}],
        sendMethod: [{ required: true, message: "请选择发送方式", trigger: "blur" }],
        sendurl: [
          { required: true, message: "请选输入自定义链接", trigger: "blur" },
          { validator: isTrueUrl, trigger: "blur" },
        ],
        keyword1: [{ required: true, message: "请输服务项目", trigger: "blur" }],
        //prize:[{ required: true, message: "请选择活动奖励", trigger: "blur"}],
      }, //表单校验
      describe: "例如：\n点击立即领取你的节日礼包", //群发内容框内容提示
      tipsInfo: "为保证微信模板消息的正常使用，请注意不要违反",
      coupon: "", //优惠卷
      timesCard: "", //计次卡
      arrayGiftData: [
        {
          giftOptions: [], //卡卷
          recSurplus: 1,
          type: "",
        },
      ],
      arrayTcountData: [
        {
          tcountOptions: [], //计次卡
          recSurplus: 1,
          type: "",
        },
      ],
      prize: 0, //活动奖励
      opens: false,
    };
  },
  created() {
    this.getListSyCoupons();
    this.editForm.tagId = this.laberCreat.labelId; //标签id
    this.editForm.sendObj = this.laberCreat.labelName; //标签参与对象
    this.editForm.sendType = this.laberCreat.laberLevel; //标签类型
    this.editForm.keyword2 = new Date();

    temp.getOneTemp().then((res) => {
      this.editForm.title = res.data.data.result.title;
      this.editForm.example = res.data.data.result.example;
      this.editForm.tempId = res.data.data.result.tempId;
      this.formOptions = res.data.data.result.content;
    });
  },
  methods: {
    // init(item){
    //     this.opens = true;
    //     this.editForm.tagId = item.labelId;//标签id
    //     this.editForm.sendObj = item.labelName;//标签参与对象
    //     this.editForm.sendType = item.laberLevel;//标签类型
    //     this.editForm.keyword2 = new Date()
    // },

    getListSyCoupons() {
      api.getListSyCoupons(0).then((res) => {
        this.arrayGiftData.giftOptions = res.data.data.result;
      });
      api.getListSyCoupons(1).then((res) => {
        this.arrayTcountData.tcountOptions = res.data.data.result;
      });
    },
    handleChange(item) {
      this.arrayGiftData.recSurplus = item.rec_surplus;
    },
    tcountChange(item) {
      this.arrayTcountData.recSurplus = item.rec_surplus;
    },
    changeType(index, item) {
      //console.log(item.giftOptions);
      this.arrayGiftData.giftOptions.forEach((v) => {
        v.disabled = false;
        for (var i = 0; i < this.arrayGiftData.length; i++) {
          if (this.arrayGiftData[i].type === v.cou_id) {
            v.disabled = true;
            v.recSurplus = this.arrayGiftData[i].recSurplus;
          }
        }
      });
    },
    changeTypeTcount() {
      this.arrayTcountData.tcountOptions.forEach((v) => {
        v.disabled = false;
        for (var i = 0; i < this.arrayTcountData.length; i++) {
          if (this.arrayTcountData[i].type === v.cou_id) {
            v.disabled = true;
          }
        }
      });
    },
    add() {
      this.arrayGiftData.push({
        giftOptions: [],
        recSurplus: 1,
        type: "",
      });
    },
    addTcount() {
      this.arrayTcountData.push({
        tcountOptions: [],
        recSurplus: 1,
        type: "",
      });
    },
    deletes(item, index) {
      if (this.arrayGiftData.length <= 1) {
        ///如果只有一个框则不可以删除
        return false;
      }
      this.arrayGiftData.splice(index, 1); //删除数组中对应的数据也就是将这个位置的框删除
      this.arrayGiftData.giftOptions.forEach((v) => {
        if (v.cou_id === item.type && v.disabled) {
          v.disabled = false;
        }
      });
    },
    deletesTcount(item, index) {
      if (this.arrayTcountData.length <= 1) {
        ///如果只有一个框则不可以删除
        return false;
      }
      this.arrayTcountData.splice(index, 1); //删除数组中对应的数据也就是将这个位置的框删除
      this.arrayTcountData.tcountOptions.forEach((v) => {
        if (v.cou_id === item.type && v.disabled) {
          v.disabled = false;
        }
      });
    },
    pointToPointClose() {
      this.arrayGiftData.giftOptions.forEach((v, index) => {
        this.arrayGiftData.splice(index); //删除数组中对应的数据也就是将这个位置的框删除
        v.disabled = false;
      });
      this.arrayTcountData.tcountOptions.forEach((v, index) => {
        this.arrayTcountData.splice(index); //删除数组中对应的数据也就是将这个位置的框删除
        v.disabled = false;
      });
      this.arrayGiftData.push({
        giftOptions: [],
        recSurplus: 1,
        type: "",
      });
      this.arrayTcountData.push({
        tcountOptions: [],
        recSurplus: 1,
        type: "",
      });
      this.editForm.tagId = "";
      this.editForm.first = "";
      this.editForm.remark = "";
      this.editForm.sendObj = "";
      this.editForm.sendType = "";
      this.editForm.keyword1 = "";
      this.editForm.keyword1 = "";
      this.editForm.sendurl = "";
      this.editForm.sendMethod = "";
      this.coupon = "";
      this.timesCard = "";
      this.prize = "";
      this.opens = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arrayGiftData = []; //卡卷
          let arrayTcountData = []; //计次卡
          let sendMethod = this.editForm.sendMethod;
          if (sendMethod === "2") {
            if (this.coupon != "" && this.coupon != false) {
              this.arrayGiftData.forEach((res) => {
                this.arrayGiftData.giftOptions.forEach((data) => {
                  if (res.type === data.cou_id) {
                    let validDate = "";
                    if (data.cou_valid_date_type === "0") {
                      var date = moment();
                      validDate = date.add(30, "days").format("YYYY-MM-DD HH:mm:ss");
                    } else if (data.cou_valid_date_type === "1") {
                      let cou_valid_date_range = data.cou_valid_date_range.split(",");
                      validDate = cou_valid_date_range[1];
                    }
                    arrayGiftData.push({
                      cou_batch: data.cou_batch,
                      cou_color: data.cou_color,
                      cou_count: data.cou_count,
                      cou_desc: data.cou_desc,
                      cou_id: data.cou_id,
                      cou_know: data.cou_know,
                      cou_me_id: data.cou_me_id,
                      cou_reminder: data.cou_reminder,
                      cou_subhead: data.cou_subhead,
                      cou_title: data.cou_title,
                      cou_type: data.cou_type,
                      cou_writer_off: data.cou_writer_off,
                      disabled: data.disabled,
                      recSurplus: res.recSurplus,
                      surplus: data.surplus,
                      frequency: data.frequency,
                      sriOverDm: validDate,
                    });
                  }
                });
              });
            }
            if (this.timesCard != "" && this.timesCard != false) {
              this.arrayTcountData.forEach((res) => {
                this.arrayTcountData.tcountOptions.forEach((data) => {
                  if (res.type === data.cou_id) {
                    let validDate = "";
                    if (data.cou_valid_date_type === "0") {
                      var date = moment();
                      validDate = date.add(30, "days").format("YYYY-MM-DD HH:mm:ss");
                    } else if (data.cou_valid_date_type === "1") {
                      let cou_valid_date_range = data.cou_valid_date_range.split(",");
                      validDate = cou_valid_date_range[1];
                    }
                    arrayTcountData.push({
                      cou_batch: data.cou_batch,
                      cou_color: data.cou_color,
                      cou_count: data.cou_count,
                      cou_desc: data.cou_desc,
                      cou_id: data.cou_id,
                      cou_know: data.cou_know,
                      cou_logo: data.cou_logo,
                      cou_reminder: data.cou_reminder,
                      cou_subhead: data.cou_subhead,
                      cou_title: data.cou_title,
                      cou_type: data.cou_type,
                      sriOverDm: validDate,
                      disabled: data.disabled,
                      recSurplus: res.recSurplus,
                      frequency: data.frequency,
                      surplus: data.surplus,
                    });
                  }
                });
              });
            }
            if (sendMethod === "2") {
              if (arrayGiftData.length === 0 && arrayTcountData.length === 0) {
                this.$notify({
                  title: "提示信息",
                  message: "请选择代金卷或计次卡",
                  type: "error",
                  position: "bottom-right",
                });
                return;
              }
            }
          }

          let params = {
            jsonParams: JSON.stringify(this.editForm),
            arrayGiftData: arrayGiftData,
            arrayTcountData: arrayTcountData,
          };
          const loading = this.$loading({
            lock: true,
            text: "卡卷发送中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          setTimeout(() => {
            loading.close();
            //this.pointToPointClose();
            this.getListSyCoupons();
            this.$router.go(-1);
            this.$notify({
              title: "prefect",
              message: "正在发送中。。。",
              type: "success",
              position: "bottom-right",
            });
          }, 5000);
          temp.tempMsg(params).then((res) => {
            if (res.data.data.result === "success") {
              this.$notify({
                title: "prefect",
                message: "发送成功",
                type: "success",
                position: "bottom-right",
              });
              loading.close();
              //this.pointToPointClose();
              this.$router.go(-1);
              this.getListSyCoupons();
            } else if (res.data.data.result === "quantity") {
              this.$notify({
                title: "提示信息",
                message: res.data.data.title + "超出卡卷剩余数量",
                type: "error",
                position: "bottom-right",
              });
              loading.close();
            } else if (res.data.data.result === "error") {
              this.$notify({
                title: "提示信息",
                message: "找不到accessToken，请在微信公众号配置IP白名单",
                type: "error",
                position: "bottom-right",
              });
              loading.close();
            } else {
              loading.close();
            }
          });
        }
      });
    },
    returnClike() {
      this.$router.go(-1);
    },
  
  },
};
</script>
<style scoped>
.content {
  position: relative;
}
.body {
  background-color: #edf0f5;
  height: 90vh;
}
.alert-success {
  background-color: #f4f8fb !important;
  color: #7a8094;
  border: aliceblue;
  margin: 15px 0px 0px 0px !important;
  padding: 10px;
}
.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 6px;
}
.c_content {
  background-color: #f4f8fb;
  padding: 15px;
  float: right;
  width: 100%;
  position: relative;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.gengduoxian {
  color: #242d3c;
  padding: 15px 15px 5px 0;
  font-size: 15px;
  margin: -15px 0 15px 0;
  border-bottom: 1px solid #eceff5;
  position: relative;
}
.infoLine {
  display: inline-block;
  width: 6px;
  height: 16px;
  background: #506ee4;
  vertical-align: baseline;
  line-height: 1;
  margin-right: 3px;
  position: relative;
  top: 2px;
}
.gengduoxian span {
  float: right;
}
.note-inf {
  color: #b3b2b2;
  font-size: smaller;
}
.form-group {
  margin-bottom: 15px;
}
.tips {
  margin-top: 10px;
  color: #908c8d;
  font-size: 12px;
  width: 1000px;
  margin-left: 0px;
}
.charge-rule-box {
  width: 180px !important;
  background: #fff;
  padding: 0px 12px 12px 12px;
  border-radius: 5px;
  padding-top: 3px;
  position: relative;
  left: 50px;
  box-sizing: 0px 2px 4px rgb(31 30 47 / 10%);
}
.col-md-6 {
  width: 392px;
  padding-left: 0px;
  padding-right: 15px;
}
.text-center {
  position: absolute;
  bottom: -50px;
  left: 45%;
}
.popov-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
